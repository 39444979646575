<template>
  <RFModal :modalId="modalId" title="Change Month Slot Status" :size="size" :loading="loading" @send="save"
           ok-label="Confirm">
    <div class="message">
      <b-row class="mt-4">
        <b-col class="message-fields">Are you sure you want to change this month slot status?</b-col>
      </b-row>
    </div>
  </RFModal>
</template>

<script>
import RFModal from "@/components/modals/RFModal";
import {adminService} from "@/services/admin";

export default {
  name: "ChangeAvailabilityModal.vue",
  components: {
    RFModal
  },
  props: {
    selectedSlot:Object
  },
  data() {
    return {
      modalId: 'change-availability-modal',
      size: 'lg',
      loading: false,
    }
  },
  methods: {
    async save() {
      console.log(this.selectedSlot)
      const {data: slotUpdated, status} = await adminService.updateCalendarSlot({
        accommodationId: this.selectedSlot.accommodationId,
        date: this.selectedSlot.date,
        isApartment: this.selectedSlot.isApartment,
        isBlocked: this.selectedSlot.blocked,
        isAvailable: this.selectedSlot.availability
      })
      if (status === 200) {
        this.$toasted.success(`Month status successfully changed`)
      }
      else{
        this.$toasted.error('An error occurred')
      }
      this.$root.$emit('bv::hide::modal', this.modalId)
      this.$emit('edited-calendar-slot')
    }
  }

}
</script>

<style lang="scss" scoped>
  .message{
    .message-fields {
      @include font-style($montserrat-bold, 'bold', $font-16);
      text-align: center;
    }
  }
</style>
