<template>
  <div class="admin-table">
    <div class="d-flex align-items-center justify-content-between mb-4">
      <h1 class="admin-title mb-0">{{$t(title)}}</h1>
      <div class="d-flex">
        <RFButton label="Update" size="small" background="#24425B" @on-click="$emit('update-list')"/>
        <RFButton v-if="unitsButton" label="Show units" size="small" background="#24425B" @on-click="$emit('show-units')"/>
        <RFButton v-else label="Show reservations" size="small" background="#24425B" @on-click="$emit('show-reservations')"/>
        <JsonCSV v-if="!manageAvailability"
          :data = "items"
          :name = "getCsvName()">
          <RFButton label="Export to CSV" size="small" background="#24425B"/>
        </JsonCSV>
        <ChangeAvailabilityModal :selectedSlot="selectedSlot" @edited-calendar-slot="updateCalendarSlot()"></ChangeAvailabilityModal>
      </div>
    </div>
    <b-table class="mt-5" responsive hover sticky-header :items="items" :fields="fields"
             :per-page="perPage" :current-page="currentPage" v-if="items.length && !loading">
      <template v-slot:head()="data">
        <div class="table-label">
          {{data.label}}
        </div>
      </template>

      <template v-slot:cell()="data">
        <div class="table-data" v-if="!manageAvailability">
          <template v-if="data.value.availability === FREE_LABEL">
            <FontAwesomeIcon :icon="['fas', 'check-circle']" :color="'#24425B'"/>
          </template>
          <template v-else-if="data.value.availability === OCCUPIED_LABEL">
            <FontAwesomeIcon :icon="['fas', 'minus-circle']" :color="'#E5D8C3'"/>
          </template>
          <template v-else>{{data.value}}</template>
        </div>
        <div class="table-data" v-else>
          <template v-if="data.value.visibility=== UNBLOCKED_LABEL">
            <FontAwesomeIcon :icon="['fas', 'check-circle']" :color="'#17d92e'" title="Block slot" @click="editCalendarSlot(data.item.index,data.value.month,data.item.accommodationId,data.value.date,data.value.visibility,true)"/>
          </template>
          <template v-else-if="data.value.visibility === BLOCKED_LABEL">
            <FontAwesomeIcon :icon="['fas', 'minus-circle']" :color="'#ef2d1e'" title="Unblock slot" @click="editCalendarSlot(data.item.index,data.value.month,data.item.accommodationId,data.value.date,data.value.visibility,false)"/>
          </template>
          <template v-else-if="data.value.visibility === OCCUPIED_LABEL">
            <FontAwesomeIcon :icon="['fas', 'minus-circle']" :color="'#E5D8C3'"/>
          </template>
          <template v-else>{{data.value}}</template>
        </div>
      </template>
    </b-table>
    <b-pagination
      class="mt-4"
      v-if="!loading && items.length > perPage"
      v-model="currentPage"
      :total-rows="items.length"
      :per-page="perPage" />
    <div class="admin-loading" v-if="loading">
      <RFLoader class="mr-3" color="#24425B" :size="30" /> {{$t('Uploading...')}}
    </div>
    <div class="empty-list" v-if="!loading && !items.length">
      {{$t('There are no items.')}}
    </div>
  </div>
</template>

<script>
import RFButton from '@/components/forms/RFButton'
import RFLoader from '@/components/forms/RFLoader'
// https://www.npmjs.com/package/vue-json-csv
import JsonCSV from 'vue-json-csv'
import ChangeAvailabilityModal from "@/components/admin/ChangeAvailabilityModal";

export default {
  name: "AdminAvailabilityTable",
  components: {
    ChangeAvailabilityModal,
    RFButton,
    RFLoader,
    JsonCSV
  },
  props: {
    list: Array,
    title: String,
    loading: Boolean,
    type: String,
    perPage: {
      type: Number,
      default: 10,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    unitsButton: {
      type: Boolean,
      default: false,
    },
    //RF-87
    manageAvailability: {
      type: Boolean,
      default: false,
    },
    isApartment: {
      type:Boolean,
      default:false
    }
  },
  data(){
    return {
      items: [],
      fields: [],
      monthsQty: 12,
      months: [
        { key: 'jan', label: 'Jan', number: '01', ordinal: 0 },
        { key: 'feb', label: 'Feb', number: '02', ordinal: 1 },
        { key: 'mar', label: 'Mar', number: '03', ordinal: 2 },
        { key: 'apr', label: 'Apr', number: '04', ordinal: 3 },
        { key: 'may', label: 'May', number: '05', ordinal: 4 },
        { key: 'jun', label: 'Jun', number: '06', ordinal: 5 },
        { key: 'jul', label: 'Jul', number: '07', ordinal: 6 },
        { key: 'aug', label: 'Aug', number: '08', ordinal: 7 },
        { key: 'sep', label: 'Sep', number: '09', ordinal: 8 },
        { key: 'oct', label: 'Oct', number: '10', ordinal: 9 },
        { key: 'nov', label: 'Nov', number: '11', ordinal: 10 },
        { key: 'dec', label: 'Dec', number: '12', ordinal: 11 },
      ],
      OCCUPIED_LABEL: 'Occupied',
      FREE_LABEL: 'Free',
      //RF-87
      BLOCKED_LABEL: 'Blocked',
      UNBLOCKED_LABEL: 'Unblocked',
      DATE_FORMAT: 'YYYY-MM-DD',
      selectedSlot:null,
      selectedIndex:null,
      selectedMonth:null
    }
  },
  beforeMount() {
    const months = this.getNextTwelveMonths()
    this.setFields(months)
    this.setItems(months)
    console.log(this.items)
  },
  methods: {
    getNextTwelveMonths() {
      const months = []
      const currentMonth = new Date().getMonth()
      const year = new Date().getFullYear()
      for(let i = 0; i < this.monthsQty; i++) {
        months.push({
          ...this.months.find(m => m.ordinal === (currentMonth + i) % this.monthsQty),
          year: currentMonth + i >= this.monthsQty ? year + 1 : year //Si me paso para el siguiente año, le sumo 1
        })
      }
      return months
    },
    setFields(months) {
      this.fields.push({
        key: 'name',
        label: 'Name'
      })
      months.forEach(m => {
        this.fields.push({
          key: m.key,
          label: m.label
        })
      })
    },
    setItems(months) {
      let auxIndex = 0
      this.list.forEach(item => {
        const row = {index:auxIndex,name: item.name, accommodationId:item.id}
        months.forEach(m => {
          if(!this.manageAvailability){
            row[m.key] = {availability: item.periods.some(p => p.monthValue === m.ordinal + 1 && p.year === m.year) ?
                this.OCCUPIED_LABEL : this.FREE_LABEL}
          }
          else{
            row[m.key] = {visibility: item.periods.some(p => p.monthValue === m.ordinal + 1 && p.year === m.year) ?
                this.OCCUPIED_LABEL : (item.blockedSlots.some(p => p.monthValue === m.ordinal + 1 && p.year === m.year) ?  this.BLOCKED_LABEL : this.UNBLOCKED_LABEL)
              ,date: item.slots.find(s => s.monthValue === m.ordinal + 1 && s.year === m.year).date,month:m.key}
          }
        })
        this.items.push(row)
        auxIndex++
      })
    },
    getCsvName() {
      return `${this.title.replaceAll(' ', '')}-${new Date().toISOString()}.csv`
    },
    //RF-87
    editCalendarSlot(index,month,accommodationId,date,visibility,blocked){
      this.selectedSlot = {accommodationId:accommodationId, date:date, availability:(visibility !== this.OCCUPIED_LABEL),blocked:blocked,isApartment:this.isApartment}
      this.selectedIndex = index
      this.selectedMonth = month
      this.$root.$emit('bv::show::modal', 'change-availability-modal')
    },
    updateCalendarSlot(){
      this.$emit('update-list')
      this.items[this.selectedIndex][this.selectedMonth].visibility = this.selectedSlot.blocked ? this.BLOCKED_LABEL : this.UNBLOCKED_LABEL
      console.log(this.items[this.selectedIndex][this.selectedMonth])
      this.selectedIndex = null
      this.selectedMonth = null
    }
  }
}
</script>

<style lang="scss" scoped>
.admin-table {
  .table-label {
    @include font-style($montserrat-bold, 'medium', $font-12);
  }
}
</style>
